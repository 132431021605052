import('./bootstrap').then(({ mount }) => {
    const localRoot = document.getElementById('supply-layout-root')

    mount({
        mountPoint: localRoot!,
        routingStrategy: 'browser',
    })
})

export {}
